@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

$custom-primary: mat.define-palette(mat.$teal-palette, 700, 400, 600);
$custom-accent: mat.define-palette(mat.$yellow-palette, 700, 400, 600);
$custom-warn: mat.define-palette(mat.$red-palette, 700, 400, 600);

$custom-theme: mat.define-light-theme((
 color: (
   primary: $custom-primary,
   accent: $custom-accent,
   warn: $custom-warn
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.core-theme($custom-theme);
@include mat.all-component-themes($custom-theme);


$color-config:    mat.get-color-config($custom-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:    map.get($color-config, 'warn');

.color-primary {
  color: mat.get-color-from-palette($primary-palette) !important;
}
.bg-color-primary {
  background-color: mat.get-color-from-palette($primary-palette) !important;
}
.color-accent {
  color: mat.get-color-from-palette($accent-palette) !important;
}
.bg-color-accent {
  background-color: mat.get-color-from-palette($accent-palette) !important;
}
.color-warn {
  color: mat.get-color-from-palette($warn-palette) !important;
}
.bg-color-warn {
  background-color: mat.get-color-from-palette($warn-palette) !important;
}
