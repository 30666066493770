/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  &.v_center {
    align-items: center;
  }

  &.h_center {
    justify-content: center;
  }

  &.h_between {
    justify-content: space-between;
  }
  .col {
    width: 49%;
  }
}
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 1.5rem; }
.mb-3 { margin-bottom: 2rem; }
.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 1.5rem; }
.mt-3 { margin-top: 2rem; }
.ml-1 { margin-left: 1rem; }
.ml-2 { margin-left: 1.5rem; }
.ml-3 { margin-left: 2rem; }
.mr-1 { margin-right: 1rem; }
.mr-2 { margin-right: 1.5rem; }
.mr-3 { margin-right: 2rem; }
